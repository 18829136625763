import React, {type PropsWithChildren} from 'react';
import dynamic from 'next/dynamic';
import UserProvider from '@deezer/react-user';
import ApiProvider from '@deezer/react-legacy-api';
import AuthProvider from '@providers/AuthProvider';
import TrackingProvider from '@providers/TrackingProvider';
import ThemeProvider from '@providers/ThemeProvider';
import {config} from '@modules/config';
import {useSyncCookiesAndQuery} from '@modules/auth/hooks';

const DynamicPipeProvider = dynamic(() => import('@providers/GraphQlProvider'));

const AppRoot: React.FC<PropsWithChildren & {withGraphQl?: boolean}> = ({
	children,
	withGraphQl,
}) => {
	const synced = useSyncCookiesAndQuery();

	if (!synced) return null;

	return (
		<ApiProvider
			host={config.get('host_website_ssl')}
			mode="cors"
			credentials="include"
		>
			<UserProvider
				hostAccount={config.get('host_account')}
				pictureDomain={config.get('host_img_url_ssl')}
			>
				<TrackingProvider>
					<ThemeProvider>
						{withGraphQl ? (
							<AuthProvider>
								<DynamicPipeProvider>{children}</DynamicPipeProvider>
							</AuthProvider>
						) : (
							children
						)}
					</ThemeProvider>
				</TrackingProvider>
			</UserProvider>
		</ApiProvider>
	);
};

export default AppRoot;
